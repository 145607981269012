(function ($) {
	"use strict";

	// Mean Menu
	$('.mean-menu').meanmenu({
		meanScreenWidth: "999",
		onePage:true
	});

	// Search Popup JS
	$(".others-option .search-btn").on("click", function () {
		// $(".search-overlay").toggleClass("search-overlay-active");
		// $(".search-overlay-results").toggleClass("display-block");
		// $("body").css("overflow","hidden");
	});

	$(".search-overlay-close").on("click", function () {
		$(".search-overlay").removeClass("search-overlay-active");
		$(".search-overlay-results").removeClass("display-block");
		$("body").css("overflow", "auto");
	});

	// Header Sticky
	$(window).on('scroll', function () {
		var currentRoute = window.location.pathname;
		if (currentRoute.indexOf('listing-detail') === -1) {
			if ($(this).scrollTop() > 10) {
				if (!$('.navbar-area').hasClass("is-sticky")) {
					$('.navbar-area').addClass("is-sticky");
				}
			}
			else {
				$('.navbar-area').removeClass("is-sticky");
			}
		}

	});
	let c, currentScrollTop = 150,
		navbar = $('.navbar-area');
	$(window).scroll(function () {
		let a = $(window).scrollTop();
		let b = navbar.height();
		currentScrollTop = a;
		if (c < currentScrollTop && a > b + b) {
			navbar.addClass("scrollUp");
		} else if (c > currentScrollTop && !(a <= b)) {
			navbar.removeClass("scrollUp");
		}
		c = currentScrollTop;
	});

	// Nice Select JS
	$('select').niceSelect();


	// Tabs
	(function ($) {
		$('.tab ul.tabs').addClass('active').find('> li:eq(0)').addClass('current');
		$('.tab ul.tabs li a').on('click', function (g) {
			let tab = $(this).closest('.tab'),
				index = $(this).closest('li').index();
			tab.find('ul.tabs > li').removeClass('current');
			$(this).closest('li').addClass('current');
			tab.find('.tab-content').find('div.tabs-item').not('div.tabs-item:eq(' + index + ')').slideUp();
			tab.find('.tab-content').find('div.tabs-item:eq(' + index + ')').slideDown();
			g.preventDefault();
		});
	})(jQuery);

	// Isotop Js
	let $grid = $('.destinations-items, .listing-items, .blog-items').isotope({
		itemSelector: '.grid-item',
		percentPosition: true,
		masonry: {
			// Use outer width of grid-sizer for columnWidth
			columnWidth: '.grid-item'
		}
	});

	// Popup Video
	$('.popup-youtube').magnificPopup({
		disableOn: 320,
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});

	// Instagram Slides


	// Listing Details Image Slides


	// Article Image Slides


	// Products Details Image Slides
	$('.products-details-image-slides').slick({
		dots: true,
		speed: 500,
		fade: false,
		slide: 'li',
		slidesToShow: 1,
		autoplay: true,
		autoplaySpeed: 4000,
		prevArrow: false,
		nextArrow: false,
		responsive: [{
			breakpoint: 800,
			settings: {
				arrows: false,
				centerMode: false,
				centerPadding: '40px',
				variableWidth: false,
				slidesToShow: 1,
				dots: true
			},
			breakpoint: 1200,
			settings: {
				arrows: false,
				centerMode: false,
				centerPadding: '40px',
				variableWidth: false,
				slidesToShow: 1,
				dots: true
			}
		}],
		customPaging: function (slider, i) {
			return '<button class="tab">' + $('.slick-thumbs li:nth-child(' + (i + 1) + ')').html() + '</button>';
		}
	});

	// Input Plus & Minus Number JS
	$('.input-counter').each(function () {
		var spinner = jQuery(this),
			input = spinner.find('input[type="text"]'),
			btnUp = spinner.find('.plus-btn'),
			btnDown = spinner.find('.minus-btn'),
			min = input.attr('min'),
			max = input.attr('max');

		btnUp.on('click', function () {
			var oldValue = parseFloat(input.val());
			if (oldValue >= max) {
				var newVal = oldValue;
			} else {
				var newVal = oldValue + 1;
			}
			spinner.find("input").val(newVal);
			spinner.find("input").trigger("change");
		});
		btnDown.on('click', function () {
			var oldValue = parseFloat(input.val());
			if (oldValue <= min) {
				var newVal = oldValue;
			} else {
				var newVal = oldValue - 1;
			}
			spinner.find("input").val(newVal);
			spinner.find("input").trigger("change");
		});
	});

	// Odometer JS
	$('.odometer').appear(function (e) {
		var odo = $(".odometer");
		odo.each(function () {
			var countNumber = $(this).attr("data-count");
			$(this).html(countNumber);
		});
	});

	// Feedback Slides

	// Price Range Slider JS
	$(".js-range-of-price").ionRangeSlider({
		type: "double",
		drag_interval: true,
		min_interval: null,
		max_interval: null,
	});

	// Go to Top
	$(function () {
		// Scroll Event
		$(window).on('scroll', function () {
			var scrolled = $(window).scrollTop();
			if (scrolled > 600) $('.go-top').addClass('active');
			if (scrolled < 600) $('.go-top').removeClass('active');
		});
		// Click Event
		$('.go-top').on('click', function () {
			$("html, body").animate({ scrollTop: "0" }, 100);
		});
	});

	// WoW JS
	$(window).on('load', function () {
		if ($(".wow").length) {
			var wow = new WOW({
				boxClass: 'wow',      // Animated element css class (default is wow)
				animateClass: 'animated', // Animation css class (default is animated)
				offset: 20,         // Distance to the element when triggering the animation (default is 0)
				mobile: true,       // Trigger animations on mobile devices (default is true)
				live: true,       // Act on asynchronously loaded content (default is true)
			});
			wow.init();
		}
	});

}(jQuery));